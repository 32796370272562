import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NewCustomer from "./pages/NewCustomer";
import OldCustomer from "./pages/OldCustomer";
import AccessNumber from "./pages/AccessNumber";
import Contact from "./pages/Contact";
import CalendarComponent from "./admin/Calendar";
import ConfirmationPage from "./pages/ConfirmationPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/book" element={<NewCustomer />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/confirmation" element={<ConfirmationPage />} />

        {/* Modified route with an email parameter */}
        <Route path="/customer/:email" element={<OldCustomer />} />
        <Route path="/accessNumber" element={<AccessNumber />} />
        <Route path="/calendar" element={<CalendarComponent />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
